.contact{
    margin: 0;
}

.other2{
    display: none !important;
}
.contact .form{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    height: calc(100vh - 100px);
    width: 100%;
    background-image: url("../images/contact.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.contact .form .content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact .form .content h1{
    font-size: 3rem;
    font-family: var(--heading-font);
    color: white;
    margin-bottom: 20px;
}
.contact .form .content input,textarea{
    width: 100%;
    padding: 5px 0;
    margin-bottom: 7vh;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid white;
}
.contact .form .content input::placeholder,textarea::placeholder{
    color: white;
}
.contact .form .content .btns{
    display: flex;
    justify-content: center;
}
.contact .form .content button{
    margin-top: 10px;
    width: 200px;
    padding: 10px;
    font-size: 18px;
    background-color: transparent;
    border: 2px solid rgb(50, 213, 42);
    color: rgb(50, 213, 42);
    cursor: pointer;
    transition: all 0.3s;
}
.contact .form .content button:hover{
background-color: rgb(50, 213, 42);
color: white;
}
.contact .form .img{
    display: flex;
    justify-content: center;
    align-items: center;
    mix-blend-mode: multiply;
}
.contact .form .img img{
    width: 80%;
    animation: contact 3s infinite;
}

.contact .other{
    margin: 20px 0;
    grid-gap: 20px;
    padding: 30px 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 100%;
    height: 100%;
}
.contact .other .all div{
    display: flex;
    margin-bottom: 8vh;
    align-items: center;
}
.contact .other .all div div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
}
.contact .other .all div div p{
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0px;
    font-family: var(--heading-font);
    color: white;
}
.contact .other .all div div a{
    font-size: 17px;
    font-family: var(--para-font);
    color: white;
}

.contact .other div .icon{
    font-size: 50px;
    color: white;
    margin-right: 20px;
}

.contact .form .content .forms{
    width: 30vw;
    height: 75vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 7%;
    background-color: rgba(0, 0, 0, 0.644);

    
}

@keyframes contact{
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-50px);
        scale: 0.9;

    }
    100%{
        transform: translateY(0px);
    }
}

@media screen and (max-width:1250px) {
.contact .form .content .forms{
    width: 40vw;
}
}
@media screen and (max-width:950px) {
.contact .form{
    padding: 20px 0;
    grid-template-columns: 1fr;
    height: 100%;
}
    .contact .form .content .forms{
        width: 80vw;
    }
    
.other1{
    display: none !important;
}
    
.other2{
    display: flex !important;
}
    }

    @media screen and (max-width:650px) {
        .contact .form .content .forms{
            width: 95vw;
        }
        .contact .form .content h1{
            font-size: 2rem;
        }
        .contact .other .all div div p{
            font-size: 17px;
        }
        .contact .other .all div div a{
            font-size: 15px;
        }
        .contact .other div .icon{
            font-size: 30px;
            margin-right: 10px;
        }
        }