@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

:root{
  --heading-font:"Poppins", sans-serif;
  --para-font: "Maitree", serif;
}

.poppins-thin {
    font-family: "Poppins", sans-serif; /*Primary font*/
    font-weight: 100;
    font-style: normal;
  }

  .maitree-extralight {
    font-family: "Maitree", serif; /*Secondary font*/
    font-weight: 200;
    font-style: normal;
  }


