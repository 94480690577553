*{
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    border: none;
    box-sizing: border-box;
}
.main-heading{
    text-align: center;
    margin-bottom: 30px;
    font-size: 25px;
    font-family: var(--heading-font);
    font-weight: 500;
    max-width: 300px;
    margin: 30px auto;
    border-bottom: 2px solid black;
}
