
.home{
    margin: 20px 3rem;
}
.home .mains{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}
/* .home .mains .accessories.parent{
    display: none;
} */
.home .mains .parent{
    /* border: 1px solid black; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}
.home .mains .parent .detail{
    position: absolute;
    text-align: center;
    font-family: var(--heading-font);
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s;
}
.home .mains .parent:hover .detail{
    background-color: rgba(0, 0, 0, 0.084);
    
}
/* .home .mains .parent:hover .parent img{
    scale: 1.05;
    
} */
.home .mains .parent .detail p{
    font-size: 30px;
    font-weight: 600;
    color: white;
}
.home .mains .parent .detail button{
    padding: 10px 10px;
    border: 2px solid white;
    color: white;
    background: transparent;
    width: 120px;
    margin:0 auto;
    cursor: pointer;
}
.home .mains video{
width:100%;
}
.home .brands{
    margin: 50px 0;
}
.home .brands .list{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
}
.home .brands .list img{
    width: 100%;
    background-color: white;
}
.home .brands .list .brand{
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}
.home .brands .list .brand:hover{
    opacity: 0.5;
    scale: 1.04;
}
.products{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
}

@media screen and (max-width:950px) {
    .home .mains{
        grid-template-columns: 1fr 1fr;
    }
    .home .mains .accessories.parent{
        display: flex;
    }
    .home .mains .parent .detail p{
        font-size: 25px;

    }
    .home .mains .parent .detail button{
        padding: 10px 10px;
        border: 2px solid white;
        color: white;
        background: transparent;
        width: 120px;
        margin:0 auto;
        cursor: pointer;
    }
    .home .brands .list{
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;
    }
    .home{
        margin: 20px 2rem;
    }
}
@media screen and (max-width:650px) {
    .home .brands .list{
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    }
    .home{
        margin: 20px 1rem;
    }
    .home .mains{
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
}