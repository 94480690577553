.wishlist{
    width: 100%;
    min-height: 80vh;
    background-color: rgb(239, 239, 239);
}
.wishlist .no-data{
    width: 100%;
    height: 80vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--heading-font);
}
.wishlist .no-data img{
    width: 100px;
    margin-bottom: 10px;
}
.wishlist .no-data h1{
    font-size: 4rem;
    margin-bottom: 10px;
}
.wishlist .no-data p{
    color: gray;
    font-family: var(--para-font);
    margin-bottom: 10px;
}
.wishlist .no-data button{
    border: 2px solid black;
    cursor: pointer;
    color: black;
    background-color: transparent;
    font-size: 17px;
    font: 600;
    letter-spacing: 1px;
    padding: 8px 20px;
    transition: all 0.3s ease-in-out;
}
.wishlist .no-data button:hover{
background-color: black;
color: white;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.wishlist .items{
    padding: 20px 3rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap:15px;
}
.wishlist .items .wish-card{
padding: 5px;
position: relative;
background-color: white;

}
.wishlist .items .wish-card:hover{
    box-shadow: 0 4px 4px 0 rgba(142, 142, 142, 0.2), 0 6px 10px 0 rgba(135, 135, 135, 0.19);

}
.wishlist .items .wish-card .cancel{
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgba(219, 219, 219, 0.763);
    color: black;
    padding: 2px;
    cursor: pointer;
}
.wishlist .items .wish-card img{
    width: 100%;
    align-self: 1/1;
    margin-bottom: 10px;
}

.wishlist .items .wish-card .name{
    font-weight: 600;
    font-size: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.3em; 
    text-align: center;
    color: black;
    margin-bottom: 10px;
}
.wishlist .items .wish-card .price{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    color: rgb(28, 106, 223);
    font-size: 20px;
    letter-spacing: 2px;
}


@media screen and (max-width:1250px) {
    .wishlist .items{
        grid-template-columns: repeat(4,1fr);
    }
}
@media screen and (max-width:950px) {
    .wishlist .items{
        grid-template-columns: repeat(3,1fr);
        padding: 20px 2rem;
    }
}
@media screen and (max-width:650px) {
    .wishlist .items{
        grid-template-columns: repeat(2,1fr);
        padding: 20px 0.5rem;
        grid-gap: 5px;

    }
}