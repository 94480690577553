.thank{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    font-family: var(--heading-font);
}
.thank .thank-div{
    padding: 10px 1rem;
}
.thank .thank-div h1{
font-size: 3rem;
letter-spacing: 1px;
word-spacing: 4px;
margin-bottom: 10px;
}
.thank .thank-div h2{
    font-size: 1.5rem;
    letter-spacing: 1px;
    word-spacing: 4px;
    margin-bottom: 3px;
    }
.thank .thank-div p{
    color: rgb(75, 75, 75);
    word-spacing: 2px;
    font-size: 15px;
}
.thank .thank-div p span{
    color: blue;
    font-weight: 600;
    margin: 0 5px;
}
.thank .thank-div img{
    width: 200px;
    margin: 20px 0;
}
.thank .thank-div button{
    width: 300px;
    padding: 12px 0;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: black;
    color: white;
    /* margin-top: 50px; */
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 30px auto 0 auto;
    text-align: center;
    justify-content: center;
    box-shadow: 0 4px 4px 0 rgba(142, 142, 142, 0.404), 0 6px 10px 0 rgba(135, 135, 135, 0.413);
    transition: all 0.2s linear;

}
.thank .thank-div button:hover{
    scale: 0.95;
}
.thank .thank-div button span:nth-child(2){
    margin-left: 10px;
    font-size: 30px;
}