.product .top img{
    width: 100%;
    aspect-ratio: 1/1;
}
.product:hover{
    box-shadow: 0 4px 4px 0 rgba(142, 142, 142, 0.2), 0 6px 10px 0 rgba(135, 135, 135, 0.19);

}
.product{
    /* border: 1px solid rgb(218, 218, 218); */
    background-color: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.product .sold{
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: black;
    font-weight: 500;
    top: 0;
    left: 0;
    background-color: rgba(203, 203, 203, 0.42);
}
.product .sold p{
    background-color: white;
    padding: 0 5px;
}


.product .wish-icon{
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 15px;
    background-color: rgb(245, 245, 245);
    padding: 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
}
.product{
    font-family: var(--heading-font);
    padding: 10px;

}
.product .bottom{
    padding: 10px;
}
.product .bottom .name{
    font-weight: 500;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em; 
    text-align: center;
    color: black;
}
.product .bottom .star{
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.product .bottom .price{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 500;
    color: rgb(28, 106, 223);
    font-size: 20px;
}
.product .bottom .price .cross{
    font-size: 10px;
    margin-right: 10px;
    text-decoration: line-through;
    color: gray;
}
.product .bottom .price .net{
    font-weight: 500;
    color: rgb(28, 106, 223);
    font-size: 20px;
}