.trackings{
    width: 100%;
    min-height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(241, 241, 241);
    padding: 20px 0;
}
.trackings .track{
    width: 80%;
    height: 100%;
    font-family: var(--heading-font);
}
.trackings .track .no-detail{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.trackings .track .no-detail .number{
    font-size: 30px;
    font-weight: 500;
    color: black;
}
.trackings .track .no-detail .tracks{
    font-size: 30px;
    font-weight: 500;
    color: rgb(28, 106, 223);
}
.trackings .track .no-detail p{
    margin-top: 5px;
    color: gray;
}

.trackings .track .details{
    width: 100%;
    margin-top: 20px;
    background-color: white;
}
.trackings .track .details .source{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10%;
    border-bottom: 1px solid rgb(189, 189, 189);

}
.trackings .track .details .source .path{
    width: 70%;
    background-color: rgb(197, 220, 255);
    height: 4px;
    border-radius: 20px;
    position: relative;
    display: flex;
    align-items: center;

}
.trackings .track .details .source .path::before{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: rgb(28, 106, 223);
    border-radius: 50%;
    left: 0;
}
.trackings .track .details .source .path::after{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: rgb(28, 106, 223);
    border-radius: 50%;
    right: 0;
}
.trackings .track .details .source .path .mark{
    position: absolute;
    left: 0;
    width: 50%;
    background-color: rgb(28, 106, 223);
    height: 4px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}
.trackings .track .details .source .path .mark::after{
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    background-color: rgb(28, 106, 223);
    border-radius: 50%;
    right: 0;
}
.trackings .track .details .info{
    display: grid;
    grid-template-columns: 1fr 1.5fr;
}
.trackings .track .details .info .left{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-right: 1px solid rgb(189, 189, 189);
    padding: 10px;
}
.trackings .track .details .info .left img{
    width: 150px;
    align-self: 1/1;
}
.trackings .track .details .info .left .msg{
    margin: 5px 0;
    font-size: 22px;
    font-weight: 500;
}
.trackings .track .details .info .left .desc{
    margin-bottom: 5px;
    color: gray;
    font-size: 15px;
    font-family: var(--para-font);
}
.trackings .track .details .info .left .time{
    margin-bottom: 5px;
    color: rgb(92, 92, 92);
    font-size: 15px;
    font-weight: 500;
}

.trackings .track .details .info .right{
    padding: 10px;
}
.trackings .track .details .info .right .id{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 10px;
}
.trackings .track .details .info .right .address{
    padding: 5px;
    background-color: rgb(240, 240, 240);
    margin-bottom: 10px;
}
.trackings .track .details .info .right .address .heads{
    font-weight: 500;
    color: black;
    margin-bottom: 5px;
    font-size: 17px;
    font-family: var(--heading-font);
}
.trackings .track .details .info .right .address p{
    color: rgb(91, 91, 91);
    margin-bottom: 0px;
    font-size: 14px;
    font-family: var(--para-font);
}



@media screen and (max-width:650px) {
    .trackings .track{
        width: 90%;
    }
}