.orders{
    padding: 20px 3rem;
    background-color: rgba(244, 244, 244, 0.838);
    font-family: var(--heading-font);
}

.orders .path{
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.orders .path .paths{
    display: flex;
    align-items: center;
    font-family: var(--heading-font);
    word-spacing: 1px;
    font-weight: 500;
    font-size: 14px;
}
.orders .path .paths span{
    margin: 0 5px;
    cursor: pointer;
    letter-spacing: 1px;
    color: rgb(28, 106, 223);

}

.orders .order-list{
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 15px;
    align-items: center;
}
.orders .order-list .order{
    padding: 5px;
    background-color: white;
    transition: all 0.3s;
}
.orders .order-list .order:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.orders .order-list .order img{
    width: 100%;
    aspect-ratio: 1/1;
    margin-bottom: 15px;
}
.orders .order-list .order .info{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
} 
.orders .order-list .order .info ul li{
    font-weight: 500;
    margin-bottom: 7px;
    color: black;
}
.orders .order-list .order .info ul:nth-child(2){
    text-align: right;
}

.sOrder{
    padding: 20px 3rem;
    background-color: rgba(244, 244, 244, 0.838);
    font-family: var(--heading-font);
}
.sOrder .num{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.sOrder .items{
    background-color: white;
    padding: 10px;
}
.sOrder .items .head{
    display: grid;
    grid-template-columns:1fr 3fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    text-align: center;
    font-weight: 600;
    border-bottom: 1px solid black;
    padding: 10px 0;
    margin-bottom: 15px;

}
.sOrder .items .item{
    margin-bottom: 10px;
    padding: 10px 0;
    border-bottom: 1px solid rgb(165, 165, 165);
    display: grid;
    grid-template-columns:1fr 7fr;
    grid-gap: 10px;
    text-align: center;
}
.sOrder .items .item .details{
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}
.sOrder .items .item .details1{
    display: none;
}
.sOrder .items .item img{
    width: 100%;
    aspect-ratio: 1/1;
    border: 1px solid rgb(165, 165, 165);
    padding: 5px;
}
.sOrder .items .item .name{
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em; 
    font-weight: 500;
}

.sOrder .info{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    background-color: white;
    margin-top: 10px;
    padding: 10px;
} 
.sOrder .info ul li{
    font-weight: 500;
    margin-bottom: 7px;
    color: black;
}
.sOrder .info ul:nth-child(2){
    text-align: right;
}


@media screen and (max-width:950px) {
    .sOrder{
        padding: 20px 2rem;
    }
    .orders{
        padding: 20px 2rem;
    }
.sOrder .items .head{
    display: none;
}
.sOrder .items .item{
    text-align: left;
    grid-template-columns: 1fr 3fr;
}
.sOrder .items .item .details{
    display: none;
}
.sOrder .items .item .details1{
    display: grid;
}

.sOrder .items .item .details1 p span{
    font-weight: 500;
}
.orders .order-list{
    grid-template-columns: repeat(3,1fr);
    grid-gap: 10px;
}
}

@media screen and (max-width:650px) {
    .sOrder{
        padding: 20px 1rem;
    }
    .orders{
        padding: 20px 1rem;
    }
    .orders .order-list{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 8px;
    }
}