.collections{
    margin-bottom: 50px;
    font-family: var(--heading-font);
    position: relative;
    /* position: absolute; */
}
.collections .loader{
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.collections .loader .loading{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

}
.collections .loader .loading img{
    width: 15vw;
    aspect-ratio: 1/1;
    background-color: transparent;
    mix-blend-mode: multiply;
}
.collections .collection-banner{
    width: 100%;
    min-height: 50vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 100px;
    z-index: 1;
}
.collections .collection-banner .heading{
    font-size: 4rem;
    margin-bottom: 5px;
    font-weight: 600;
}
.collections .collection-banner .path{
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: rgb(43, 43, 43);
}
.collections .collection-banner .path span{
    margin: 0 5px;
}
.collections .collection-list{
    padding: 50px 3rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem 2rem;
    z-index: 3;
    background-color: white;
    position: relative;
    top: 50vh;
    clear: both;
    margin-bottom: 50vh;
}
.collections .collection-list .collection{
    text-align: center;
    transition: all 0.3s;
    padding-bottom: 10px;
    border: 1px solid rgb(223, 223, 223);
    /* box-shadow: 0 4px 4px 0 rgba(142, 142, 142, 0.2), 0 6px 10px 0 rgba(135, 135, 135, 0.19); */


}
.collections .collection-list .collection:hover{
    box-shadow: 0 4px 4px 0 rgba(142, 142, 142, 0.2), 0 6px 10px 0 rgba(135, 135, 135, 0.19);

}
.collections .collection-list .collection img{
    width: 100%;
    margin-bottom: 1px;
    aspect-ratio: 1/1;
    /* border: 1px solid gray; */

}
.collections .collection-list .collection .name{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    color:rgb(28, 106, 223);
    font-family: var(--para-font);
}
.collections .collection-list .collection .product-qty{
    font-size: 14px;
    color: gray;
    word-spacing: 3px;
    margin-bottom: 10px;
}
.collections .collection-list .collection button{
    border: 2px solid black;
    padding: 8px 2px;
    width: 90%;
    margin: 0 auto;
    background-color: transparent;
    transition: all 0.3s;
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
}
.collections .collection-list .collection button:hover{
    background-color: black;
    color: white;
}
@media screen and (max-width:1250px) {
    .collections .collection-list{
        grid-gap: 3rem 1rem;
        grid-template-columns: repeat(3,1fr);
    }
}

@media screen and (max-width:1150px) {
    .collections .collection-list{
        grid-gap: 3rem 1rem;
    }
    .collections .collection-list .collection button{
        width: 90%;
    }
}
@media screen and (max-width:950px) {
    .collections .collection-banner{
        top: 60px;
    }
    .collections .collection-list{
        grid-gap: 3rem 1rem;
        padding: 50px 1rem;
        grid-template-columns: 1fr 1fr;
    }
    .collections .collection-list .collection button{
        width: 80%;
    }
}
@media screen and (max-width:650px) {
    .collections .collection-banner .heading{
        font-size: 3rem;
    }
    .collections .collection-list{
        grid-gap:5px;
        padding: 50px 0.2rem;
        grid-template-columns: 1fr 1fr;
    }
    .collections .collection-list .collection{
        /* border: 1px solid rgb(214, 214, 214); */
        box-shadow: 0 4px 4px 0 rgba(142, 142, 142, 0.2), 0 6px 10px 0 rgba(135, 135, 135, 0.19);

    }
    .collections .collection-list .collection button{
        width: 90%;
        font-size: 12px;
        letter-spacing: normal;
        padding: 10px 2px;
        background-color: black;
        color: white;
    }
    .collections .collection-list .collection img{
        margin-bottom: 5px;
    }
    .collections .collection-list .collection .name{
        font-size: 18px;
        margin-bottom: 0px;
    }
    .collections .collection-list .collection .product-qty{
        font-size: 12px;
        word-spacing: 2px;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }
}