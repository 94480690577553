.footer{
    margin-top: 50px;
    background-color: #0b0b0b;
    font-family: var(--heading-font);
    padding: 20px 3rem;
    position: relative;
    z-index: 2;
}
.footer .footer1{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px 10px;
    color: white;
}
.footer .footer1 div p{
    font-size: 19px;
    margin-bottom: 15px;
    font-weight: 600;
    position: relative;
}
.footer .footer1 div p::after{
position: absolute;
content: "";
width: 100px;
bottom: -1px;
background-color: white;
height: 1px;
left: 0;
}
.footer .footer1 div li{
    font-size: 14px;
    margin-bottom: 10px;
    color: #f2f2f2;
}
.footer .footer1 div .track{
    display: flex;
    flex-direction: column;
}
.footer .footer1 div .track div{
    display: flex;
}
.footer .footer1 div .track input{
    padding: 10px;
    outline: none;
    border: none;
}
.footer .footer1 div .track button{
background-color: rgb(28, 106, 223);
padding: 0 10px;
cursor: pointer;
font-weight: 500;
font-size: 16px;
color: white;
}

.footer .logo{
    color: white;
    text-align: center;
    font-family: "Lobster", sans-serif;
    font-size: 110px;
    margin-top: 20px;
}
.footer .copy{
    color: white;
    text-align: center;
    border-top: 1px solid white;
    padding: 5px 0;
    font-size: 14px;
}

@media screen and (max-width:950px) {
    .footer .footer1{
        grid-template-columns: 1fr 1fr;
    }
    .footer{
        padding: 20px 2rem;
    }
}
@media screen and (max-width:650px) {
    .footer .footer1{
        grid-template-columns: 1fr;
    }
    .footer .logo{
        font-size: 90px;
    }
    .footer{
        padding: 20px 1rem;
    }
}
@media screen and (max-width:450px) {
    .footer .logo{
        font-size: 60px;
    }
}
