
.header .header1{
    font-family: var(--heading-font);
}
.header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    transition: transform 0.2s ease;
  }
  
.header--hidden {
    transform: translateY(-100%);
}
.header .header2{
    display: none;
}
.header .header1 .head1{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 15px;
    padding: 5px 2rem;
    height: 55px;
    align-items: center;
}
.header .header1 .head1 img{
    max-width: 200px;
    cursor: pointer;
    max-height: 40px;
}
.header .header1 .head1 .search{
    border: 1px solid rgb(71, 71, 71);
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding:2px 10px;
    width: 80%;
    margin: 0 auto;
}
.header .header1 .head1 .search input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}
.header .header1 .head1 .search span{
    margin-left: 5px;
    cursor: pointer;
    font-size: 30px;


}
.header .header1 .head1 .currency{
    display: flex;
    align-items:center;
    justify-content: flex-end;
    width: 100%;
    font-weight: 600;
}
.header .header1 .head1 .currency select{
    font-size: 17px;
    border: none;
    outline: none;
}
.header .header1 .head1 .logout{
    margin-left: 3rem;
    color: black;
}
.header .header1 .head1 .logout span{
    color: black;
}

.header .header1 .head2{
    background-color: #0b0b0b;
    display: grid;
    grid-template-columns:9fr 3fr;
    color: white;
    padding: 5px 2rem;
    height: 50px;
    align-items: center;

}
.header .header1 .head2 ul{
    display: flex;
    align-items: center;
}
.header a{
    color: white;
    font-family: var(--para-font);
    letter-spacing: 1px;
}
.header .header1 .head2 ul li{
    margin-right: 2.5rem;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.3s;
}
.header .header1 .head2 ul li:hover{
    color: rgb(255, 255, 0);
}
.header .header1 .head2 .icons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.header .header1 .head2 .icons span{
    font-size: 30px;
}
.header .header1 .head2 .icons .cart{
    margin-left: 3rem;
}
.header .header2 .head1{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 5px 1rem;
    height: 60px;
    border-bottom: 1px solid gray;
}
.header .header2 .head1 img{
    max-width: 200px;
    cursor: pointer;
    max-height: 40px;
    margin-left: 20px;
}
.header .header2 .head1 .icons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
} 
.header .header2 .head1 .icons span{
    font-size: 20px;
}
.header .header2 .head1 .icons .cart{
    margin-left: 0.8rem;
}

.header .header2 .head1 .ham{
    display: flex;
    align-items: center;
}
.header .header2 .head1 .ham span{
    font-size: 27px;
    /* border: 1px solid black; */
}
.mobile-search{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.861);
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
}
.mobile-search .main-search{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;

}
.mobile-search .main-search .search-bar{
    width: 90%;
    display: flex;
    align-items: center;
}
.mobile-search .main-search .search-bar span{
    color: rgb(160, 160, 4);
    background-color: white;
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.mobile-search input{
    padding: 8px 2px;
    width: 100%;
    border-bottom: 2px solid white;
    outline: none;
    background-color: transparent;
    color: white;
    font-size: 18px;

}
.mobile-search input::placeholder{
    color: white;
    font-size: 18px;
}
.mobile-search .main-search .icos{
    position: absolute;
    top: 10px;
    right:10px;
    color: white;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(190, 190, 190, 0.564);
    border-radius: 50%;
    padding: 10px;
    width: 35px;
    height: 35px;

}

.header .header2 .head2{
    font-family: var(--heading-font);
}
.header .header1 .head2 .icons span{
    position: relative;
    cursor: pointer;

}
.header .header1 .head2 .icons span p{
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    color: white;
    top: 4px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    /* border: 2px solid white; */
}
.header .header2 .head1 .icons span{
    position: relative;
    font-size: 30px;
    cursor: pointer;
}
.header .header2 .head1 .icons span p{
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: red;
    color: white;
    top: -5px;
    right: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    font-weight: 600;
    border: 2px solid white;

}

.header .header2 .head2{
    width: 300px;
    position: fixed;
    top: 60px;
    height: calc(100vh - 58px);
    transition: all 0.3s;
    border-right: 1px solid gray;
    background-color: white;
}
.header .header2 .head2 .profile{
    background-color: rgb(237, 237, 237);
    padding: 10px;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
}
.header .header2 .head2 .profile img{
    width: 50px;
    height: 50px;
    border: 1px solid gray;
    border-radius: 50%;
    margin-right: 20px;
}
.header .header2 .head2 .profile .name{
    font-size: 19px;
    font-weight: 500;
}
.header .header2 .head2 .logout{
    position: fixed;
    bottom: 0;
    padding: 10px 0;
    width: 300px;
    font-size: 20px;
    font-weight: 600;
    border-top: 2px solid gray;
}
.header .header2 .head2 .logout p{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header .header2 .head2 .pages{
   margin-top: 30px;
   margin-left: 1.5rem;
}
.header .header2 .head2 .pages li{
    border: 1px solid rgb(237, 237, 237);
    border-radius: 20px 0 0 20px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: gray;
    margin-bottom: 1rem;
    cursor: pointer;
}
.header .header2 .head2 .pages li select{
    font-family: 600;
    font-size: 15px;
    color: gray;

}
.header .header2 .head2 .pages li span{
    margin: 0 5px;
}
.header .header2 .head2 .pages li span:nth-child(1){
    font-weight: 300;
}
.header .header2 .head2 .pages li .kid{
    font-size: 27px;
}

.header .header2 .head2 .logout p span{
    margin: 0 10px;
}
.header .logo{
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin-left: 15px;
    color: rgb(27, 27, 27);

}

.header .header2 .head2 ul li{
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 25px;
    font-weight: 600;
    transition: all 0.3s;
    /* border: 1px solid gray; */
    padding: 5px;
}
.cart-draw{
    top: 0;
    position: fixed;
    background-color: rgb(255, 255, 255);
    width: 35vw;
    height: 100vh;
    padding: 20px;
    transition: all 0.2s;
    padding: 0 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 11;
}
 .carts{
    
    
 }   

.carts .no-data{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}
.carts .back-icon{
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 35px;
    cursor: pointer;
    transition: all 0.3s;
}
.carts .back-icon:hover{
    scale: 1.05;
}
.carts .no-data img{
    width: 150px;
    margin-bottom: 10px;
}
.carts .no-data .no-data-para{
    font-size: 30px;
    font-family: var(--heading-font);
    margin-bottom: 0px;
    font-weight: 500;
}
.carts .no-data .para{
    color: gray;
    font-family: var(--heading-font);
    font-size: 12px;
    margin-bottom: 20px;
}
.carts .no-data button{
    background-color: black;
    font-size: 14px;
    color: white;
    padding: 10px 25px;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(104, 104, 104, 0.2), 0 6px 20px 0 rgba(103, 103, 103, 0.19);

}
.carts .items{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    max-height: 68vh;
    position: absolute;
    padding: 0 20px;

}
.carts .items::-webkit-scrollbar{
    display: none;
}
.carts .cart-name{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    margin-top: 15px;
    justify-content: center;
}
.carts .cart-name span:nth-child(1){
    font-size: 25px;
    font-family: var(--heading-font);
    margin-right: 10px;
    font-weight: 500;
}
.carts .items .item{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-gap: 10px;
    border-bottom: 1px solid rgb(193, 193, 193);
    padding: 10px 0;
    font-family: var(--heading-font);
    margin-bottom: 15px;
    align-items: center;
}
.carts .items .item img{
    width: 100%;
    border: 1px solid rgb(193, 193, 193);
}
.carts .items .item .info{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
 .carts .items .item .info .name{
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em; 
    font-weight: 500;
    font-size: 17px;
}
 .carts .items .item .info .color,.size{
    font-size: 14px;
}
 .carts .items .item .info .qty{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(193, 193, 193);
    width: 30%;
    margin-top: 5px;
    padding: 0 5px;
}
 .carts .items .item .info .qty span{
    color: gray;
    cursor: pointer;
}
 .carts .items .item .price{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 10px;
    height: 100%;
}
 .carts .items .item .price .cancel{
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}
 .carts .items .item .price .pricing{
    font-weight: 600;
    font-size: 17px;
    color: rgb(28, 106, 223);
}
 .carts .items .checkout{
    position: fixed;
    padding: 0 20px;
    bottom: 0;
    background-color: white;
    padding-top: 10px;
    border-top: 1px solid rgb(193, 193, 193);
    width: 35%;
}
 .carts .items .checkout .total{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 28px;
    font-family: var(--heading-font);
    font-weight: 600;
}
 .carts .items .checkout button{
    color: white;
    background-color: black;
    width: 100%;
    padding: 15px 0;
    font-size: 17px;
    letter-spacing: 2px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 10px;
}
 .carts .items .checkout button:hover{
    color: #faaf00;
}

@media screen and (max-width:1250px) {
    .header .header1 .head1 .search{
        width: 100%;
    }
    .cart-draw{
        width: 45%;
    }
     .carts .items .checkout{
        width: 45%;
    }

}
@media screen and (max-width:950px) {
    .header .header1{
        display: none;
    }
    .header .header2{
        display: block;
    }
    .cart-draw{
                width: 70%;
    }
     .carts .items .checkout{
        width: 70%;
    }
    .header--hidden {
        transform: translateY(0);
    }
    .no-scroll {
        overflow: hidden;
      }
    .overlay {
        position: fixed;
        top: 60px;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
        z-index:0; /* Ensure it's above other content */
      }
}
@media screen and (max-width:650px){
    .cart-draw{        width: 90%;
    }
     .carts .items .checkout{
        width: 90%;
    }
     .carts .items{
        padding: 0 10px;
    }
     .carts .items .item{
        display: grid;
        grid-template-columns: 1.5fr 3fr 1fr;
        grid-gap: 10px;
        border-bottom: 1px solid rgb(193, 193, 193);
        padding: 10px 0;
        font-family: var(--heading-font);
        margin-bottom: 15px;
        align-items: center;
    }
    
}
