.main-products .loader{
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
.main-products .loader .loading{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

}
.overlay1{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    background-color: rgba(0, 0, 0, 0.331);
    z-index: 11;
}
.main-products .loader .loading img{
    width: 15vw;
    aspect-ratio: 1/1;
    background-color: transparent;
    mix-blend-mode: multiply;
}
.main-products .mobile{
    background-color: rgba(244, 244, 244, 0.838);
}

.main-products .mobile .path{
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-products .mobile .path .paths{
    display: flex;
    align-items: center;
    font-family: var(--heading-font);
    word-spacing: 1px;
    font-weight: 500;
    font-size: 14px;
}
.main-products .mobile .path .paths span{
    margin: 0 5px;
    cursor: pointer;
    letter-spacing: 1px;
    color: rgb(28, 106, 223);

}
.main-products .mobile .filter{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 10px;
    margin: 10px 1rem;
    background-color: white;
}
.main-products .mobile .filter .option{
    display: flex;
    align-items: center;
    font-family: var(--para-font);
    font-weight: 600;
    font-size: 17px;    
}
.main-products .mobile .filter .option span{
    margin-left: 10px;
}
.main-products .mobile .filter .option:nth-child(2){
    justify-content: flex-end;
}
.main-products .mobile .filter .filter-box{
    position: fixed;
    z-index: 12;
    background-color: white;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 10px 0;
    font-family: var(--heading-font);
    min-height: 40vh;
    transition: all 0.3s ease-in-out;
}
.main-products .mobile .filter .filter-box .headers{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    font-weight: 400;
    letter-spacing: 1px;
    border-bottom: 1px solid rgb(112, 112, 112);
}
.main-products .mobile .filter .filter-box .filter-body{
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 20px;
    margin-top: 1px;
    min-height: 40vh;
}
.main-products .mobile .filter .filter-box .filter-body .left{
    background-color: rgb(244, 244, 244);
} 
.main-products .mobile .filter .filter-box .filter-body .left ul li{
padding: 15px 10px;
font-weight: 400;
border-bottom: 1px solid rgb(193, 193, 193);
cursor: pointer;
}
.main-products .mobile .filter .filter-box .filter-body .left ul .selected{
    background-color: white;
    border-left: 3px solid rgb(28, 106, 223);
}
.main-products .mobile .filter .filter-box .filter-body .right .content{
    margin: 10px 0;
}
.main-products .mobile .filter .filter-box .filter-body .right .content p{
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
}
.main-products .mobile .filter .filter-box .filter-body .right .content ul{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.main-products .mobile .filter .filter-box .filter-body .right .content ul li{
    border: 1px solid rgb(193, 193, 193);
    padding: 0px 6px;
    font-weight: 500;
    border-radius: 15px;
    margin-right: 7px;
    margin-bottom: 7px;
    font-size: 12px;
    cursor: pointer;
}
.main-products .mobile .filter .filter-box .filter-body .right .content ul li.selected{
    border: 1px solid rgb(28, 106, 223);
    color: rgb(28, 106, 223);
}
.main-products .mobile .filter .filter-box .filter-foot{
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    padding: 15px 10px;
    font-weight: 400;
    letter-spacing: 1px;
    border-top: 1px solid rgb(112, 112, 112);
}
.main-products .mobile .filter .filter-box .filter-foot .count{
    font-size: 15px;
}
.main-products .mobile .filter .filter-box .filter-foot .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.main-products .mobile .filter .filter-box .filter-foot .right button{
    margin-left: 10px;
    padding: 10px;
    border-radius: 5px;
    width: 100px;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 12px;
}
.main-products .mobile .filter .filter-box .filter-foot .right button:nth-child(1){
    border: 1px solid rgb(28, 106, 223);
    color: rgb(28, 106, 223);
    background-color: transparent;
}
.main-products .mobile .filter .filter-box .filter-foot .right button:nth-child(2){
    border: 1px solid rgb(28, 106, 223);
    color: white;
    background-color: rgb(28, 106, 223);
}
.main-products .mobile .filter .sort-box{
    position: fixed;
    z-index: 12;
    background-color: white;
    left: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;
    padding: 10px 0;
    font-family: var(--heading-font);
    min-height: 40vh;
    transition: all 0.3s ease;
}
.main-products .mobile .filter .sort-box .headers{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    font-weight: 400;
    letter-spacing: 1px;
    border-bottom: 1px solid rgb(112, 112, 112);
}
.main-products .mobile .filter .sort-box .sort-body{
    margin-top: 1px;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.main-products .mobile .filter .sort-box .sort-body p{
    border: 1px solid rgb(193, 193, 193);
    padding: 10px 10px;
    font-weight: 500;
    border-radius: 15px;
    margin-right: 7px;
    margin-bottom: 7px;
    font-size: 15px;
    cursor: pointer;
    margin: 10px 15px;
}
.main-products .mobile .filter .sort-box .sort-body p.selected{
    border: 1px solid rgb(28, 106, 223);
    color: rgb(28, 106, 223);
}
.main-products .mobile .products-section{
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.main-products .mobile .products-section .products-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
.main-products .mobile .products-section .pagination{
    margin: 20px 0;
}

@media screen and (min-width:950px) {
    .main-products .mobile .products-section{
        margin: 0 2rem;
    }
    .main-products .mobile .products-section .products-list{
        display: grid;
        grid-template-columns:repeat(3,1fr);
        grid-gap: 15px;
    }
    .main-products .mobile .filter{
        margin: 10px 2rem;
    }
}  
@media screen and (min-width:1250px) {
    .main-products .mobile .products-section{
        margin: 0 3rem;
    }
    .main-products .mobile .products-section .products-list{
        display: grid;
        grid-template-columns:repeat(4,1fr);
        grid-gap: 15px;
    }
    .main-products .mobile .filter{
        margin: 10px 3rem;
    }
} 
@media screen and (min-width:1550px) {
    .main-products .mobile .products-section{
        margin: 0 4rem;
    }
    .main-products .mobile .products-section .products-list{
        display: grid;
        grid-template-columns:repeat(5,1fr);
        grid-gap: 15px;
    }
    .main-products .mobile .filter{
        margin: 10px 4rem;
    }
} 
@media screen and (max-width:950px) {
    .main-products .loader .loading img{
        width: 50vw;
        aspect-ratio: 1/1;
        background-color: transparent;
    }
}   

@media screen and (max-width:650px) {
    .main-products .mobile .filter{
        padding: 10px 10px;
        margin: 10px 0.2rem;
    }
    .main-products .mobile .products-section{
        margin: 0 0.2rem;
    }
    .main-products .mobile .products-section .products-list{
        grid-gap: 6px;
    }
}