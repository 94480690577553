.policy{
    margin: 20px 3rem;
}
.policy .policies{

}
.policy .policies img{
    float: right;
    width: 30vw;
    aspect-ratio: 1/1;
    clear: none;
    margin-top: 10px;
    margin-left: 20px;
}
.policy .policies h1{
    font-family: var(--heading-font);
    letter-spacing: 1px;
    margin-bottom: 10px;
    
}
.policy .policies p{
    clear: none;
    font-family: var(--para-font);
    color: rgb(49, 49, 49);
    text-align: justify;
}


@media screen and (max-width:950px) {
    .policy{
        margin: 10px 2rem;
    }
    .policy .policies img{
        width: 40vw;
        margin-left: 10px;
    }
    
}

@media screen and (max-width:650px) {
    .policy{
        margin: 10px 1rem;
    }
    .policy .policies img{
        width: 60vw;
        margin-left: 10px;
        margin-top: 10px;
    }
    .policy .policies h1{
        margin-bottom: 10px;
        font-size: 20px;
        
    }
    .policy .policies p{
        font-size: 14px;
    }
    
}
@media screen and (max-width:400px) {
    .policy .policies img{
        width: 80vw;
        aspect-ratio: 1/1;
        float: left;
        margin-bottom: 10px;
        /* margin: 10px 5vw; */
    }
    .policy .policies h1{
        margin-bottom: 5px;
        font-size: 20px;
        clear: none;
        text-align: center;
        
    }
    .policy .policies p{
        font-size: 14px;
        clear: left;
    }
    
}