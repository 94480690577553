.checkouts{
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-gap: 30px;
    font-family: var(--heading-font);
    border: 1px solid rgb(235, 235, 235);
    position: relative;
    z-index: 1;
}
.checkouts .left{
    padding: 20px 0;
    padding-left: 3rem;
}
.checkouts .left .scrolling .form div{
    width: 100%;
    /* margin-bottom: 10px; */
}

.checkouts .left .scrolling .form .contact{
    margin-bottom: 20px;
}
.checkouts .left .scrolling .form input{
    width: 100%;
}
.checkouts .left .scrolling .form p{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.checkouts img{
    width: 100px;
}
.checkouts .left .scrolling .form .delivery{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px 0;
} 
.checkouts .left .scrolling .form .delivery .name{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
}
.checkouts .left .scrolling .form .delivery .city{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
}
.checkouts .left .scrolling .payment{
    margin-top: 25px;
}
.checkouts .left .scrolling .payment p{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.checkouts .left .scrolling .payment .pay{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(198, 198, 198);
    padding: 10px 20px;
    border-radius: 5px;
}
.checkouts .left .scrolling .payment .pay img{
    width: 100px;
}
.checkouts .left .scrolling .payment .pay img.ok{
    width: 30px;
}
.checkouts .left .scrolling .complete{
    margin-top: 35px;
}
.checkouts .left .scrolling .complete button{
    background-color:rgb(28, 106, 223);
    box-shadow: 0 4px 4px 0 rgba(224, 236, 255, 0.2), 0 6px 10px 0 rgba(213, 220, 255, 0.19);
    width: 100%;
    padding: 15px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}
.checkouts .right{
    background-color: rgb(245, 245, 245);
    padding: 20px;
    padding-right: 3rem;
    border-left: 1px solid rgb(220, 220, 220);

    
}
.checkouts .right .prdts{
    margin-bottom: 20px;
}
.checkouts .right .prdts .prdt{
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    border-bottom: 1px solid rgb(214, 214, 214);
    /* align-items: center; */
}
.checkouts .right .prdts .prdt .left{
    padding: 0;
    display: flex;
    /* align-items: center; */
}
.checkouts .right .prdts .prdt .left .img{
    position: relative;
    margin-right: 20px;
    /* border-radius: 10px; */
}
.checkouts .right .prdts .prdt .left img{
    border: 1px solid rgb(209, 209, 209);
    padding: 5px;

    width: 100px;
    /* border-radius: 10px; */
}
.checkouts .right .prdts .prdt .left .img p{
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    background-color: gray;
    color: white;
    font-size: 15px;
    font-weight: 500;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkouts .right .prdts .prdt .left .name{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 7px;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em; 
}
.checkouts .right .prdts .prdt .left .color,.size{
    margin-bottom: 5px;
    word-spacing: 5px;
}
.checkouts .right .prdts .prdt .amount{
    font-size: 18px;
    font-weight: 600;
}
.checkouts .right .apply{
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.checkouts .right .apply div{
    width: 100%;
}
.checkouts .right .apply button{
    background-color:rgb(28, 106, 223);
    box-shadow: 0 4px 4px 0 rgba(224, 236, 255, 0.2), 0 6px 10px 0 rgba(213, 220, 255, 0.19);
    width: 150px;
    padding: 17px 5px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 10px;
}
.checkouts .right .total{
    display: flex;
    align-self: center;
    justify-content: space-between;
    margin-top: 50px;
}
.checkouts .right .total p{
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}
.checkouts .right .total .left{
    padding: 0;
}
.checkouts .right .total .right{
    text-align: right;
    border: none;
    padding: 0;
}
.checkouts .right .total .final{
    font-size: 28px;
}
/* #zoid-paypal-buttons-uid_2ac421eae9_mte6nti6mtg{
    height: 97vh;
} */
.checkouts .left .scrolling .complete .App{
overflow-y: scroll;
max-height: calc(100vh - 60px);
}
.checkouts .left .scrolling .complete .App::-webkit-scrollbar{
    display: none;
}
@media screen and (max-width:1250px) {
    .checkouts{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width:950px) {
    .checkouts{
        grid-template-columns: 1fr;
        margin-bottom: 200px;
    }
    .checkouts .left{
        padding:10px 1rem;
    }
    .checkouts .right{
        padding:10px 1rem;
    }
    .checkouts .complete{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        padding: 10px 1rem;
        background-color: white;
    }
    .checkouts .right .prdts .prdt .left img{
        width: 80px;
    }
}