.singlep{
    margin: 20px 0;
    width: 100%;
}
.singlep .loader{
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh; 

}
.singlep .loader .loading{
    top: 0;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

}
.singlep .loader .loading img{
    width: 15vw;
    aspect-ratio: 1/1;
    background-color: transparent;
    mix-blend-mode: multiply;

}
.singlep .single-product{
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-gap: 20px;
    margin:0 2rem;
}
.singlep .single-product .left{
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 20px;
}
.singlep .single-product .left .main-img{
    margin-bottom: 10px;
    position: relative;

}
.singlep .single-product .left .main-img img{
    width: 100%;
    aspect-ratio: 1/1;
    padding: 10px;
    border: 1px solid grey;
}
.singlep .single-product .left .main-img img:nth-child(1){
    z-index: 1;
}
.singlep .single-product .left .main-img img:nth-child(2){
    z-index: 2;
}
.singlep .single-product .left .main-img .tag{
    position: absolute;
    left: -5px;
    top: -5px;
    width: 100px;
    padding: 0;
    border: none;
}
.singlep .single-product .left .main-img .icon{
    font-size: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
}
.singlep .single-product .left .main-img .icon:hover{
    box-shadow: 0 4px 4px 0 rgba(142, 142, 142, 0.2), 0 6px 10px 0 rgba(135, 135, 135, 0.19);

}
.singlep .single-product .left .thumbs{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 97%;
    background-color: rgb(241, 241, 241);
    padding: 10px;
    overflow: hidden;
    position: relative;
    
}
.singlep .single-product .left .thumbs span{
    background-color: white;
    padding: 0 5px;
    font-size: 30px;
    width: 100%;
    text-align: center;
}
.singlep .single-product .left .thumbs .thumb{
    display: flex;
    align-items: center;
    height: 88%;
    overflow-y:scroll;
    flex-direction: column;
    position: absolute;
    top: 48px;
    width: 86%;

}
.singlep .single-product .left .thumbs .thumb::-webkit-scrollbar{
    display: none;
}
.singlep .single-product .left .thumbs .thumb img{
    width: 100%;
    aspect-ratio: 1/1;
    margin:5px 0px;
    cursor: pointer;
}
.singlep .single-product .right{
    /* margin-right: 20px; */
    padding: 0 20px;
}
.singlep .single-product .right .brand{
    font-family:var(--heading-font);
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    position: relative;
    margin-bottom: 20px;
}

.singlep .single-product .right .star{
    margin-bottom: 25px;
}
.singlep .single-product .right .title{
    font-family: var(--heading-font);
    font-weight: 600;
    font-size: 45px;
    margin-bottom: 40px;
    line-height: 1;
}
.singlep .single-product .right .price{
    font-family: var(--heading-font);
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 30px;
    color: rgb(28, 106, 223);
}
.singlep .single-product .right .color{
    margin-bottom: 40px;
    font-family: var(--heading-font);

}
.singlep .single-product .right .color p{
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    display: flex;
    align-items: center;
}
.singlep .single-product .right .color p span{
    margin-left: 15px;
    padding: 1px 10px;
    border:2px solid rgb(28, 106, 223);
    font-size: 17px;
    color: black;
}
.singlep .single-product .right .color ul{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.singlep .single-product .right .color ul li{
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 17px;
    color: grey;
    border: 2px solid black;
    padding: 10px 15px;
    font: 600;
    text-transform: uppercase;
    cursor: pointer;

} 
.singlep .single-product .right .size{
    display: grid;
    grid-template-columns:repeat(4, 1fr);
    grid-gap: 20px;
    margin-bottom: 40px;
}
.singlep .single-product .right .size div{
    background-color: rgb(241, 241, 241);
    padding: 10px;
}
.singlep .single-product .right .size div select{
    width: 100%;
    padding: 10px;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    outline: none;
    background-color: white;
}
.singlep .single-product .right .buy-btns{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 10px;
}
.singlep .single-product .right .buy-btns button{
    color: white;
    font-weight: 600;
    padding: 20px 0;
    font-size: 22px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.singlep .single-product .right .buy-btns button span{
    margin-right: 10px;
    font-size: 25px;
}
.singlep .single-product .right .buy-btns button:nth-child(1){
    background-color:rgb(28, 106, 223);
    box-shadow: 0 4px 4px 0 rgba(224, 236, 255, 0.2), 0 6px 10px 0 rgba(213, 220, 255, 0.19);

}
.singlep .single-product .right .buy-btns button:nth-child(2){
    background-color:#faaf00;
    box-shadow: 0 4px 4px 0 rgba(255, 242, 215, 0.2), 0 6px 10px 0 rgba(255, 246, 210, 0.19);

}
.singlep .desc{
    margin: 50px 0;
}
.singlep .desc .tabs{
    display: flex;
    justify-content: center;
    align-items: center;
}
.singlep .desc .tabs .colors span{
    color: black;
}
.singlep .desc .css-19kzrtu{
    color: rgb(72, 72, 72);
    font-family: var(--para-font);
}


@media screen and (max-width:1600px) {
    .singlep .single-product .left .thumbs .thumb{
        height: 83%;
    }
    .singlep .single-product .right .brand{
        font-size: 18px;
        margin-bottom: 10px;
    }
    
    .singlep .single-product .right .star{
        margin-bottom: 15px;
    }
    .singlep .single-product .right .title{
        font-size: 35px;
        margin-bottom: 30px;
    }
    .singlep .single-product .right .price{
        font-size: 35px;
        margin-bottom: 20px;
    }
    .singlep .single-product .right .color{
        margin-bottom: 30px;
    }
    .singlep .single-product .right .color p{
        margin-bottom: 10px;
        font-size: 20px;
    }
    .singlep .single-product .right .color p span{
        padding: 1px 7px;
        font-size: 15px;
    }
    .singlep .single-product .right .color ul li{
        font-size: 14px;
        padding: 7px 10px;
        font-weight: 600;
    } 
    .singlep .single-product .right .size{
        margin-bottom: 30px;
    }
    .singlep .single-product .right .size div{
        padding: 8px;
    }
    .singlep .single-product .right .size div select{
        padding: 8px;
        font-size: 17px;
    }
    .singlep .single-product .right .buy-btns button{
        padding: 15px 0;
        font-size: 20px;
    }
    .singlep .single-product .right .buy-btns button span{
        margin-right: 8px;
        font-size: 23px;
    }
}
@media screen and (max-width:1350px) {
    .singlep .single-product .right .brand{
        font-size: 15px;
        margin-bottom: 8px;
    }
    
    .singlep .single-product .right .star{
        margin-bottom: 10px;
    }
    .singlep .single-product .right .title{
        font-size: 30px;
        margin-bottom: 20px;
    }
    .singlep .single-product .right .price{
        font-size: 30px;
        margin-bottom: 20px;
    }
    .singlep .single-product .right .color{
        margin-bottom: 20px;
    }
    .singlep .single-product .right .color p{
        margin-bottom: 7px;
        font-size: 17px;
    }
    .singlep .single-product .right .color p span{
        padding: 1px 7px;
        font-size: 13px;
    }
    .singlep .single-product .right .color ul li{
        font-size: 14px;
        padding: 5px 8px;
        font-weight: 500;
    } 
    .singlep .single-product .right .size{
        margin-bottom: 20px;
    }
    .singlep .single-product .right .size div{
        padding: 5px;
    }
    .singlep .single-product .right .size div select{
        padding: 6px;
        font-size: 15px;
    }
    .singlep .single-product .right .buy-btns button{
        padding: 8px 0;
        font-size: 16px;
    }
    .singlep .single-product .right .buy-btns button span{
        margin-right: 5px;
        font-size: 20px;
    }
    .singlep .single-product .left .thumbs .thumb{
        height: 80%;
    }
}

@media screen and (max-width:1150px) {
    .singlep .single-product .right{
        margin-right: 10px;
        padding: 0 10px;
    }
    .singlep .single-product{
        grid-gap: 10px;
    }
    .singlep .single-product .right .brand{
        font-size: 13px;
        margin-bottom: 5px;
    }
    
    .singlep .single-product .right .star{
        margin-bottom: 8px;
    }
    .singlep .single-product .right .title{
        font-size: 25px;
        margin-bottom: 15px;
    }
    .singlep .single-product .right .price{
        font-size: 25px;
        margin-bottom: 15px;
    }
    .singlep .single-product .right .color{
        margin-bottom: 15px;
    }
    .singlep .single-product .right .color p{
        margin-bottom: 5px;
        font-size: 15px;
    }
    .singlep .single-product .right .color p span{
        padding: 1px 7px;
        font-size: 12px;
    }
    .singlep .single-product .right .color ul li{
        font-size: 12px;
        padding: 3px 5px;
        font-weight: 400;
        border: 1px solid black;
    } 
    .singlep .single-product .right .size{
        margin-bottom: 15px;
    }
    .singlep .single-product .right .size div{
        padding: 5px;
    }
    .singlep .single-product .right .size div select{
        padding: 6px;
        font-size: 15px;
    }
    .singlep .single-product .right .buy-btns button{
        padding: 8px 0;
        font-size: 13px;
        font-weight: 500;
    }
    .singlep .single-product .right .buy-btns button span{
        margin-right: 5px;
        font-size: 20px;
    }
    .singlep .single-product .left .thumbs .thumb{
        height: 78%;
    }
}

@media screen and (max-width:950px){
.singlep .single-product .right .buy-btns{
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
    background-color: white;
    width: 100%;
    z-index: 1;
}
    .singlep .single-product{
        grid-template-columns: 1fr;
    }
    .singlep .single-product .right{
        margin: 20px 0;
        padding:20px 0;
    }
    .singlep .single-product .right .size{
        margin-bottom: 25px;
    }
    .singlep .single-product .left .thumbs .thumb{
        height: 84%;
    }
    
}
@media screen and (max-width:750px){
    .singlep .single-product .left .thumbs span{
        font-size: 17px;
    }
    .singlep .single-product .left .thumbs .thumb{
        height: 85%;
        top: 30px;
    }
    .singlep .single-product .left .main-img .tag{
        width: 70px;
    }
    .singlep .single-product .left .main-img .icon{
        font-size: 20px;
    }
    .singlep .single-product .right{
        margin: 0px 0;
    }
    .singlep .single-product .right .buy-btns button{
        padding: 15px 0;
    }
    .singlep .single-product .right .buy-btns{
        grid-gap: 10px;
    }
    .singlep .desc{
        margin: 20px 0;
    }
    .singlep .loader .loading img{
        width: 50vw;
        aspect-ratio: 1/1;
        background-color: transparent;
    }


}
@media screen and (max-width:600px){
    .singlep .single-product .left .thumbs .thumb{
        height: 84%;
    }
    .singlep .single-product .left{
        grid-gap: 10px;
    }
    .singlep .single-product .left .main-img .tag{
        width: 65px;
    }
    .singlep .single-product{
        margin: 0 1rem;
    }


}
@media screen and (max-width:500px){
    .singlep .single-product .left .thumbs .thumb{
        height: 80%;
    }
    .singlep .single-product .left .main-img .tag{
        width: 60px;
    }
    .singlep .single-product{
        margin: 0 0.5rem;
    }

}

