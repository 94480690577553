.about{
    position: relative;
}
.about .about-head{
    height: 60vh;
    overflow: hidden;
    position: relative;

}
.about .about-head video{
    width: 100vw;
    z-index: 1;
}
.about .about-head .content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
position: absolute;
z-index: 2;
top: 0;
left:0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.507);
/* background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.795),rgba(255, 255, 255, 0.516)); */
padding:0 10vw 50px 10vw;
}
.about .about-head .content p{
    text-align: center;
    color: white;
    font-family: var(--para-font);
    font-size: 1.3rem;

}

.about .about-head .content h1{
    font-family: var(--heading-font);
    font-size: 6rem;
    margin-bottom: 15px;
    color: white;
    text-align: center;
}
.about .about-head .content .icons{
    margin-top: 15px;
    display: flex;
    align-items: center;
}
.about .about-head .content .icons .icon{
    margin-right: 20px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 30px;
    background-color: white;
    border-radius: 5px;
}
.about .about-head .content .icons .icon:hover{
    scale: 1.05;
    

}
.about .about-head .content .icons .icon:nth-child(1){
    color: rgb(108, 108, 255);
    
}
.about .about-head .content .icons .icon:nth-child(2){
    color: rgb(255, 108, 213);
}
.about .about-body{
    margin: 50px 10rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.about .about-body .bodies{
    margin-bottom: 50px;
    grid-gap: 10px;
    padding: 10px;
    text-align: center;
}
.about .about-body .bodies img{
    width: 100%;
    height: auto;
}
.about .about-body .bodies .info .heads{
    font-family: var(--heading-font);
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
} 
.about .about-body .bodies .info p{
    font-family: var(--para-font);
    text-align: center;
}

@media screen and (max-width:1500px) {
    .about .about-head .content p{
        font-size: 1rem;
    } 
    .about .about-head .content h1{
        font-size: 4rem;
    }
    .about .about-body{
        margin: 50px 5rem;
    }
}
@media screen and (max-width:1250px) {
.about .about-body .bodies .info .heads{
    font-size: 1.3rem;
}
.about .about-body .bodies .info p{
    font-size: 0.8rem;
}
.about .about-body{
    margin: 50px 3rem;
}
.about .about-head{
    height: 55vh;
}


}
@media screen and (max-width:1050px){
    .about .about-head{
        height: 50vh;
    }
}
@media screen and (max-width:950px) {
    .about .about-body .bodies .info .heads{
        font-size: 1.1rem;
    }
    .about .about-body .bodies .info p{
        font-size: 0.8rem;
    }
    .about .about-body{
        margin: 50px 1rem;
    }
    .about .about-head .content p{
        font-size: 0.8rem;
    } 
    .about .about-head .content h1{
        font-size: 3rem;
    }
.about .about-head{
    height: 40vh;
}
.about .about-head .content{
padding:0 5vw 20px 5vw;
}
.about .about-body{
    margin: 50px 2rem;
}
   
    }

@media screen and (max-width:800px){
    .about .about-head{
        height: 35vh;
    }
}


@media screen and (max-width:650px){
    .about .about-head{
        height: 23vh;
    }
    .about .about-head .content p{
        font-size: 0.7rem;
    } 
    .about .about-head .content h1{
        font-size: 1.5rem;
    }
    .about .about-head .content{
        padding:0 1vw 10px 1vw;
        }
        .about .about-body{
            margin: 50px 1rem;
            grid-template-columns: 1fr;
        }
}

@media screen and (max-width:450px){
    .about .about-head{
        height: 18vh;
    }
    .about .about-head .content p{
        font-size: 0.5rem;
    } 
    .about .about-head .content h1{
        font-size: 1rem;
    }
}

